import React from "react";
import styles from "./Transaction.module.css";
import axios from "axios";
const Transaction = ({ client }) => {
  console.log("this is transactions page", client.transactions);

  const handleVerify = async (transaction) => {
    try {
      await axios.post("https://client-management-lilac.vercel.app/api/verifyPayment", {
        transaction:transaction,
        client_id: client._id
      });
      console.log("Transaction verified successfully!");
    } catch (error) {
      console.error("Error verifying transaction:", error.message);
    }
  };
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };
  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Transactions</h2>
      <table className={styles.transactionsTable}>
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Amount</th>
            <th>Payment Method</th>
            <th>Account Number</th>
            <th>Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {client?.transactions.map((transaction) => (
            <tr key={transaction._id}>
              <td>{transaction.transactionId}</td>
              <td>{transaction.amount}</td>
              <td>{transaction.paymentMethod}</td>
              <td>{transaction.accountNumber}</td>
              <td>{formatDate(transaction.date)}</td>
              <td>{transaction.status}</td>

              <td>
                <button
                  className={styles.verifyButton}
                  onClick={() => handleVerify(transaction)}
                  disabled={transaction.status === "successful"}
                >
                  Verify
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Transaction;