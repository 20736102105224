
import ProjectTable from "./component/ProjectTable/ProjectTable";
import Navbar from "./component/Navbar/Navbar";

function App() {
  return (
    <>
    <Navbar />
    <ProjectTable/>
    </>
  );
}

export default App;
