import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import toast from "react-hot-toast";
import styles from "./NewProject.module.css";
const NewProject = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    clientName: "",
    clientEmail: "",
    projectBudget: 0,
    paidAmount: 0,
    clientId: "",
    projectName: "",
    projectDetails: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleWebsiteDetailsChange = (value) => {
    setFormData({
      ...formData,
      projectDetails: value,
    });
  };

  const handleSubmit = () => {
    console.log("Form Data:", formData);
    setLoading(true);
    // Logic to handle form submission goes here
    axios
      .post("https://client-management-lilac.vercel.app/api/v1", formData)
      .then((res) => {
        toast.success("Client information saved successfully.");
        setSuccess(true);
      })
      .catch((err) => {
        // console.error("Error:", err);
        toast.error("Failed to save client information.");
      });
  };

  const isFormValid = () => {
    return (
      formData.clientName.trim() !== "" &&
      formData.clientEmail.trim() !== "" &&
      formData.projectName.trim() !== "" &&
      formData.projectDetails.trim() !== ""
    );
  };

  const editorStyles = {
    backgroundColor: "#2d3748",
    color: "white",
    border: "1px solid #4a5568",
  };

  return (
    <div className={styles.formContainer}>
      <h1 className={styles.formTitle}>Enter Client Information</h1>
      <div>
        <div className={styles.formGroup}>
          <label htmlFor="clientName" className={styles.formLabel}>
            Client Name
          </label>
          <input
            type="text"
            id="clientName"
            required
            name="clientName"
            value={formData.clientName}
            onChange={handleChange}
            className={styles.formInput}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="clientEmail" className={styles.formLabel}>
            Client Email
          </label>
          <input
            type="email"
            id="clientEmail"
            required
            name="clientEmail"
            value={formData.clientEmail}
            onChange={handleChange}
            className={styles.formInput}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="paidAmount" className={styles.formLabel}>
            Paid Amount
          </label>
          <input
            type="number"
            id="paidAmount"
            required
            name="paidAmount"
            value={formData.paidAmount}
            onChange={handleChange}
            className={styles.formInput}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="projectBudget" className={styles.formLabel}>
            Project Budget
          </label>
          <input
            type="number"
            id="projectBudget"
            required
            name="projectBudget"
            value={formData.projectBudget}
            onChange={handleChange}
            className={styles.formInput}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="projectName" className={styles.formLabel}>
            Project Name
          </label>
          <input
            type="text"
            id="projectName"
            name="projectName"
            required
            value={formData.projectName}
            onChange={handleChange}
            className={styles.formInput}
          />
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="projectDetails" className={styles.formLabel}>
            Project Details
          </label>
          <ReactQuill
            theme="snow"
            value={formData.projectDetails}
            onChange={handleWebsiteDetailsChange}
            style={editorStyles}
            modules={{
              toolbar: [
                [{ header: "1" }, { header: "2" }, { font: [] }],
                [{ size: [] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
            ]}
            placeholder="Enter project details..."
          />
        </div>
        <div className={styles.formGroup}>
          {success? (
            <p className={styles.formButton}>Client information saved successfully.</p>
          ) :           <>{loading ? (
            <p className={styles.formButton}>Loading...</p>
          ) : (
            <button
              disabled={!isFormValid()}
              onClick={handleSubmit}
              className={styles.formButton}
            >
              Get Client Link
            </button>
          )}</>}

        </div>
      </div>
    </div>
  );
};

export default NewProject;
