import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import styles from './ProjectTable.module.css';
import Transaction from '../Transactions/Transaction';

const ProjectTable = () => {
  const [clients, setClients] = useState([]);

  const [currentClient, setCurrentClient] = useState([]);//[client1,client2,client3,client4,client5
  const [transactionModal, setTransactionModal] = useState(false);
  function closeModal() {
    setTransactionModal(false);
  }


  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get('https://client-management-lilac.vercel.app/api/dashboard');
      setClients(data);
      setLoading(false);
      console.log("Client Data:", data);
    }
    fetchData();
  }, []);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className={styles.tableContainer}>
      <table className={styles.dataTable}>
        <thead>
          <tr>
            <th>Client Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Project Name</th>
            <th>Project Budget</th>
            <th>Paid Amount</th>
            <th>Pending Transaction</th>
            <th>Project Status</th>
          </tr>
        </thead>
        <tbody>
          {clients.map(client => (
            <tr key={client._id}>
              <td>{client.clientName}</td>
              <td>{client.email}</td>
              <td>{client.phoneNumber}</td>
              <td>{client.projectName}</td>
              <td>{client.projectBudget}</td>
              <td>{client.paidAmount}</td>
              <td>{client.pendingTransaction>0?<button className={styles.transactionButton} onClick={()=>{setTransactionModal(true);setCurrentClient(client)}}>{client.pendingTransaction}</button>:0}</td>
              <td>{client.projectStatus}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
            isOpen={transactionModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
          >
            <button onClick={closeModal}>close</button>
            <Transaction client={currentClient} />
          </Modal>
    </div>
  );
};

export default ProjectTable;